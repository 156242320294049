import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import styles from './index.module.css'

const NotFoundPage = () => (
  <Layout >
    <SEO title="404: Not found" />
    <div className={styles.nontfound}>
    <h1>Pagina no encontrada</h1>
    <p>La ruta ingresada no existe.</p>
    </div>
  
  </Layout>
)

export default NotFoundPage
